import logo from './logo.svg';
import './App.css';
import LoginPage from './Loginn';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MISDashboard from './MISDASH.jsx';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/mis-dashboard" element={<MISDashboard />} />
      </Routes>
    </Router>

    </>
  );
}

export default App;
