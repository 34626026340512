import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook from react-router-dom
import './Navbar.css'; // Make sure to have a corresponding CSS file for styling.

const Navbar = () => {
  const history = useNavigate(); // Initialize the useHistory hook

  // Define the logout function
  const handleLogout = () => {
    // Clear session storage or any other state management data you might have
    sessionStorage.clear();

    // Navigate to the home page
    history('/');
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <h1 className="navbar-logo">MIS-Dashboard</h1>
        <button onClick={handleLogout} className="navbar-logout-button">
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
