import React from 'react';
import './Navbar.css'; // Make sure to create a corresponding CSS file for styling.

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <h1 className="navbar-logo">MIS-Dashboard</h1>
      </div>
    </nav>
  );
};

export default Navbar;
