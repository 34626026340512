import React, { useState, useEffect } from 'react';
import './dash.css';
import NavbarOnboarded from './NavbarOnboarded'
import { useNavigate } from 'react-router-dom';

const MISDASH = () => {
  // State hooks for selected data point, year, month and for holding the display data.
  const [selectedDataPoint, setSelectedDataPoint] = useState('No. of new downloads');
  const [selectedYear, setSelectedYear] = useState('2022');
  const [selectedMonth, setSelectedMonth] = useState('All');
  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate()

  // Define your data points here.
  const dataPoints = {
    "No. of new downloads": {
      "2022": {
        "Oct": 52346,
        "Nov": 55463,
        "Dec": 62897
        // Complete with data if available
      },
      "2023": {
        "Jan": 66432,
        "Feb": 68754,
        "Mar": 71234,
        "Apr": 74285,
        "May": 76342,
        "Jun": 79464,
        "Jul": 81562,
        "Aug": 83193,
        "Sep": 80765,
        "Oct": 82564
        // Complete with data if available
      }
    },
    "Cumulative Downloads": {
      "2022": {
        "Oct": 52346,
        "Nov": 107809,
        "Dec": 170706
        // Complete with data if available
      },
      "2023": {
        "Jan": 237138,
        "Feb": 305892,
        "Mar": 377126,
        "Apr": 451411,
        "May": 527753,
        "Jun": 607217,
        "Jul": 688779,
        "Aug": 771972,
        "Sep": 852737,
        "Oct": 935301
        // Complete with data if available
      }
    },
    "CAC (INR)": {
      "2022": {
        // Complete with data if available
        "Oct": 12.9,
        "Nov": 12.3,
        "Dec": 11.6
      },
      "2023": {
        "Jan": 11.3,
        "Feb": 11.4,
        "Mar": 11.4,
        "Apr": 10.8,
        "May": 9.4,
        "Jun": 4.6,
        "Jul": 3.8,
        "Aug": 3.2,
        "Sep": 3.3,
        "Oct": 3.2
        // Complete with data if available
      }
    },
    "Avg. No. of Visits Per Month": {
      "2022": {
        // Complete with data if available
        "Oct": 7.49,
        "Nov": 7.89,
        "Dec": 8.12
      },
      "2023": {
        "Jan": 8.14,
        "Feb": 8.65,
        "Mar": 8.79,
        "Apr": 8.81,
        "May": 8.85,
        "Jun": 8.96,
        "Jul": 9.12,
        "Aug": 9.2,
        "Sep": 9.1,
        "Oct": 9.2
        // Complete with data if available
      }
    },
    "Time spent per session (mins)": {
      "2022": {
        // Complete with data if available
        "Oct": 6.42,
        "Nov": 6.56,
        "Dec": 6.78
      },
      "2023": {
        "Jan": 6.89,
        "Feb": 7.12,
        "Mar": 7.14,
        "Apr": 7.26,
        "May": 7.47,
        "Jun": 7.58,
        "Jul": 7.71,
        "Aug": 7.82,
        "Sep": 7.86,
        "Oct": 7.84
        // Complete with data if available
      }
    },
    "Total Monthly Active Users": {
      "2022": {
        // Complete with data if available
         // Complete with data if available
         "Oct": 174321,
         "Nov": 221986,
         "Dec": 275423
      },
      "2023": {
        "Jan": 329865,
        "Feb": 397654,
        "Mar": 432187,
        "Apr": 496382,
        "May": 512868,
        "Jun": 534766,
        "Jul": 597325,
        "Aug": 614922,
        "Sep": 635987,
        "Oct": 667987
        // Complete with data if available
      }
    },
    "Percentage of women users": {
      "2022": {
        // Complete with data if available
        "Oct": "18.34%",
         "Nov": "19.78%",
         "Dec": "20.68%"
      },
      "2023": {
        "Jan": "21.95%",
        "Feb": "22.96%",
        "Mar": "24.64%",
        "Apr": "24.86%",
        "May": "24.98%",
        "Jun": "25.01%",
        "Jul": "25.21%",
        "Aug": "25.10%",
        "Sep": "25.12%",
        "Oct": "25.11%"
        // Complete with data if available
      }
    },
    // Add additional data points as necessary
  };
  
  // Use this object within your React components to manage and display data.
  

  // Handlers for changes in dropdown selections.
  const handleDataPointChange = (event) => {
    setSelectedDataPoint(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Update table data whenever the selected options change.
  useEffect(() => {
    const id = sessionStorage.getItem('username')
    const pw = sessionStorage.getItem('password')

    if(id!='Admin' && pw!='admin#123' ){
        navigate('/')
    }

    const yearData = dataPoints[selectedDataPoint][selectedYear] || {};
    if (selectedMonth === 'All') {
      const allData = Object.entries(yearData).map(([month, value]) => ({ month, value }));
      setTableData(allData);
    } else {
      const data = yearData[selectedMonth];
      setTableData(data ? [{ month: selectedMonth, value: data }] : []);
    }
  }, [selectedDataPoint, selectedYear, selectedMonth, dataPoints]);

  // Extract years and months for the selected data point.
  const years = Object.keys(dataPoints[selectedDataPoint]);
  const months = ['All', ...Object.keys(dataPoints[selectedDataPoint][selectedYear] || {})];

  return (
<>
<NavbarOnboarded/>
<div className="dashboard">
      {/* Form for the selections */}
      <div>
        <label>Data Point:</label>
        <select value={selectedDataPoint} onChange={handleDataPointChange}>
          {Object.keys(dataPoints).map((point) => (
            <option key={point} value={point}>
              {point}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Year:</label>
        <select value={selectedYear} onChange={handleYearChange}>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Month:</label>
        <select value={selectedMonth} onChange={handleMonthChange}>
          {months.map((month) => (
            <option key={month} value={month}>
              {month === 'All' ? 'All Months' : month}
            </option>
          ))}
        </select>
      </div>

      {/* Table for displaying data */}
      
    </div>


    <div className="display-data">
  <strong>Data:</strong>
  <div className="table-container"> {/* A div to help with horizontal alignment */}
    <table className="data-table">
      <thead>
        <tr>
          <th className="table-header-month">Month</th>
          <th className="table-header-data">Data</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ month, value }) => (
          <tr key={month}>
            <td className="table-cell-month">{month}</td>
            <td className="table-cell-data">{value}</td>
          </tr>
        ))}
        {tableData.length === 0 && (
          <tr>
            <td colSpan="2" className="table-cell-no-data">No data available for this selection</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

</>
  );
};

export default MISDASH;
