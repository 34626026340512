import React, { useState } from 'react';
import './LoginPage.css'; // Make sure to create a corresponding CSS file for styling.
import {useNavigate} from 'react-router-dom'
import Navbar from './Navbar';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    // Here we check if the credentials match the admin credentials
    if (username === 'Admin' && password === 'admin#123') {
      // If the credentials are correct, navigate to the MIS dashboard page
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('password', password);
      navigate('/mis-dashboard');
    } else {
      // If credentials are incorrect, handle accordingly (e.g., show an error message)
      alert('Incorrect username or password');
    }
  };

  return (
   <>
    <Navbar/>

<div className="login-container">
  <form className="login-form" onSubmit={handleLogin}>
    <h2>Login</h2>
    <div className="input-group">
      <label htmlFor="username">Username</label>
      <input
        type="text"
        id="username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
    </div>
    <div className="input-group">
      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
    </div>
    <button type="submit" className="login-button">Log In</button>
  </form>
</div>
   </>
  );
};

export default LoginPage;
